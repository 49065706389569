import React, { Component } from 'react';
class Chat extends Component {
  constructor (props) {
    super(props);

    this.state = {
      open: false,
    }

    this.frameProps = {
      frameBorder: 0,
    };
  }

  toggle (event) {
    // Toggle the state
    this.setState({
      open: !this.state.open,
    });
  }

  render() {
    return (
      <div id="chat">
        <div id="chat-wrapper" className={this.state.open ? "" : "hide"}>
          <iframe src="https://cluelesscycling.com/firechat/" {...this.frameProps}>
          </iframe>
        </div>
        <div id="chat-launcher-container" onClick={this.toggle.bind(this)}>
          <div id="chat-launcher">
            <div id="chat-launcher-open-icon" className={this.state.open ? "hide" : ""}></div>
            <div id="chat-launcher-close-icon" className={this.state.open ? "": "hide"}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chat;
