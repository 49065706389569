import { connect } from 'react-redux';
import StatsCom from '../../components/participant/Stats.js';
import _ from 'lodash';

// Actions
import {
  toggleSecondMenu,
} from '../../store/actions.js';

import {
  topicNameFromStage,
} from '../../share/utils.js';

const mapStateToProps = (state) => {
  let participant = null;
  let posts = [];
  let allPosts = [];
  let participants = state.overall.participants;
  const topicName = topicNameFromStage(state.stage.settings);
  let mentionedParticipant = state.overall.mentionedParticipant;

  //
  // Get the participant
  if (participants[mentionedParticipant]) {
    participant = participants[mentionedParticipant];
  }

  // Get the posts from specific stage
  if (state.stage.posts[topicName]) {
		allPosts = state.stage.posts[topicName];
  }


  // Getting the post
  posts = allPosts.filter((post) => {
    if (post.data.content) {
      let content = post.data.content;
      if (typeof post.data.content === 'string') {
        content = JSON.parse(post.data.content);
      }

      // Check for the entity mapping
      if (content.entityMap) {
        return _.some(content.entityMap, (entity) => {
          if (entity.type === 'MENTION') {
            if (entity.data.url === mentionedParticipant) {
              return true;
            }
          }

          // No mention
          return false;
        });
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

	return {
    participant,
    posts,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clickMention: () => {
			dispatch(toggleSecondMenu());
		},
	}
}

// Connect the props to components
const Stats = connect(
	mapStateToProps,
  mapDispatchToProps,
)(StatsCom);

// Export the module
export default Stats;
