import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CourseMap from '../containers/CourseMap.js';
import Posts from '../containers/Posts.js';
import Situations from '../containers/Situations.js';
import Results from '../containers/Results.js';
import Info from './Info.js';
import ListTable from './ListTable.js';
import ClassNames from 'classnames';
import {
  useLatest,
  topicNameFromStage,
} from '../share/utils.js';
import _ from 'lodash';

// Styling
import {
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import PostsIcon from '@material-ui/icons/QuestionAnswer';
import ResultsIcon from '@material-ui/icons/Assignment';
import StartListIncon from '@material-ui/icons/PersonPin';

import TabContainer from './TabContainer.js';

class StageLive  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
    }
  }

  tabChanged(event, value) {
    this.setState({
      currentTab: value,
    })
  }

  render() {
    console.log('redrw the stage live');
    // Get the stage from properties
    const { stage } = this.props;
    const {
      currentTab,
    } = this.state;

    // Current stage slug
    const topicName = topicNameFromStage(stage.settings);

    // Figure out the situations
    let latestSituations = useLatest(stage.situations[topicName]);
    if (!_.isEmpty(latestSituations) && !_.isEmpty(latestSituations.data)) {
      latestSituations = latestSituations.data;
    } else {
      latestSituations = false;
    }

    console.log('latest situations', latestSituations);
    const postsClassNames = ClassNames({
      'with-situation': !_.isEmpty(latestSituations),
      'posts': true,
    });

    let index = 0;
    const indexMapping = {};
    const tabLabels = [];
    tabLabels.push(<Tab key={index} icon={<PostsIcon />} label="Posts" />);
    indexMapping[index++] = 'posts';

    // Results
    let results = [];
    if (stage.results[topicName]) {
      results = stage.results[topicName];
      tabLabels.push(<Tab key={index} icon={<ResultsIcon />} label="Results" />);
      indexMapping[index++] = 'results';
    }

    // Figure out the start list
    let startList = [];
    if (stage.startLists[topicName]) {
      startList = stage.startLists[topicName];
      tabLabels.push(<Tab key={index} icon={<StartListIncon />} label="Start List" />);
      indexMapping[index++] = 'startList';
    }

    // Add the info
    tabLabels.push(<Tab key={index} icon={<InfoIcon />} label="Info" />);
    indexMapping[index++] = 'info';

    let tabContent = null;
    switch (indexMapping[currentTab]) {
      case 'results':
        tabContent = <Results />
        break;
      case 'startList':
        tabContent = <ListTable data={startList} />
        break;
      case 'info':
        tabContent = <Info info={this.props.stage.settings} />
        break;
      default:
        tabContent = <div>
                    <div className={postsClassNames}>
                      <h2>Live Reporting</h2>
                      <Posts />
                    </div>
                    { latestSituations &&
                      <Situations situations={latestSituations} />
                     }
                    <div className="clear"></div>
          </div>
        break;
    }

    return (
          <div className="stage-live">
              <CourseMap />

              <div className="contents">
                <Tabs
                  variant="fullWidth"
                  value={currentTab}
                  onChange={this.tabChanged.bind(this)}
                  className="tab-container"
                  textColor="secondary"
                >
                  {tabLabels}
                </Tabs>

                  <TabContainer>
                    {tabContent}
                  </TabContainer>
              </div>
          </div>
    );
  }
}

export default StageLive;
