import { connect } from 'react-redux';
import PostsCom from '../components/Posts.js';

// Actions
import {
  toggleSecondMenu,
  mentionParticipant,
  getParticipant,
} from '../store/actions.js';

import {
  topicNameFromStage,
} from '../share/utils.js';


const mapStateToProps = (state) => {
  // Figure out the posts
  let posts = [];
  const topicName = topicNameFromStage(state.stage.settings);
  if (state.stage.posts[topicName]) {
		posts = state.stage.posts[topicName];
  }

	return {
    posts,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clickMention: (handle) => {
			console.log('going to toggle secondary menu');
			dispatch(toggleSecondMenu());
			dispatch(mentionParticipant(handle));
      dispatch(getParticipant(handle));
		},
	}
}

// Connect the props to components
const Posts = connect(
	mapStateToProps,
  mapDispatchToProps,
)(PostsCom);

// Export the module
export default Posts;
