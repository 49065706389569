import { connect } from 'react-redux';
import RacesCom from '../components/Races.js';

// Actions
import {
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
    races: state.overall.user.races,
    userName: state.overall.user.userName,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
	}
}

// Connect the props to components
const Races = connect(
	mapStateToProps,
	mapDispatchToProps,
)(RacesCom);

// Export the module
export default Races;

