import _ from 'lodash';
// Import the send message on ws
import { sendMsg, registerRoom } from './ws.js';
import { API_ENDPOINT } from '../share/config.js';

export const CHANGE_BAR_TITLE = 'CHANGE_BAR_TITLE';
export const WS_STATUS = 'WS_STATUS';
export const REGISTERED = 'REGISTERED';

export const UNKNOW_MESSAGE = 'UNKNOW_MESSAGE';

export const FETCHING = 'FETCHING';
export const FETCHED = 'FETCHED';
export const FETCH_ERROR = 'FETCH_ERROR';

export const RECEIVED_PARTICIPANT = 'RECEIVED_PARTICIPANT';
export const MENTION_PARTICIPANT = 'MENTION_PARTICIPANT';

export const SENDING_MESSAGE = 'SENDING_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';

// Chunk message
export const MESSAGE_CHUNK = 'MESSAGE_CHUNK';

// New data on the pipe
export const NEW_POST = 'NEW_POST';
export const NEW_LOCATION = 'NEW_LOCATION';
export const NEW_RESULT = 'NEW_RESULT';
export const NEW_SITUATIONS = 'NEW_SITUATIONS';
export const NEW_MAP = 'NEW_MAP';

export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_SECOND_MENU = 'TOGGLE_SECOND_MENU';

// User
export const FETCHING_USER_ERROR = 'FETCHING_USER_ERROR';
export const FETCHING_USER = 'FETCHING_USER';
export const RECEIVED_USER = 'RECEIVED_USER';

// Stage
export const SAVING_STAGE_SETTINGS = 'SAVING_STAGE_SETTINGS';
export const SAVING_STAGE_SETTINGS_ERROR = 'SAVING_STAGE_SETTINGS_ERROR';
export const SAVED_STAGE_SETTINGS = 'SAVED_STAGE_SETTINGS';

export const SAVING_STAGE_RESULT = 'SAVING_STAGE_RESULT';
export const SAVING_STAGE_RESULT_ERROR = 'SAVING_STAGE_RESULT_ERROR';
export const SAVED_STAGE_RESULT = 'SAVED_STAGE_RESULT';

export const FETCHING_STAGE_SETTINGS_ERROR = 'FETCHING_STAGE_SETTINGS_ERROR';
export const FETCHING_STAGE_SETTINGS = 'FETCHING_STAGE_SETTINGS';
export const RECEIVED_STAGE_SETTINGS = 'RECEIVED_STAGE_SETTINGS';

export const ADDING_RACE = 'ADDING_RACE';
export const ADDING_RACE_ERROR = 'ADDING_RACE_ERROR';
export const ADDED_RACE = 'ADDED_RACE';
export const CLEAR_ADDED_RACE = 'CLEAR_ADDED_RACE';

export const ADDING_STAGE = 'ADDING_STAGE';
export const ADDING_STAGE_ERROR = 'ADDING_STAGE_ERROR';
export const ADDED_STAGE = 'ADDED_STAGE';
export const CLEAR_ADDED_STAGE = 'CLEAR_ADDED_STAGE';

export const FETCHING_STAGE_RESULT = 'FETCHING_STAGE_RESULT';
export const FETCHING_STAGE_RESULT_ERROR = 'FETCHING_STAGE_RESULT_ERROR';
export const RECEIVED_STAGE_RESULT = 'RECEIVED_STAGE_RESULT';

export const DELETING_STAGE_RESULT = 'DELETING_STAGE_RESULT';
export const DELETING_STAGE_RESULT_ERROR = 'DELETING_STAGE_RESULT_ERROR';
export const DELETED_STAGE_RESULT = 'DELETED_STAGE_RESULT';

// Race
export const FETCHING_RACES = 'FETCHING_RACES';
export const FETCHING_RACES_ERROR = 'FETCHING_RACES_ERROR';
export const RECEIVED_RACES = 'RECEIVED_RACES';

export const SAVING_RACE_SETTINGS = 'SAVING_RACE_SETTINGS';
export const SAVING_RACE_SETTINGS_ERROR = 'SAVING_RACE_SETTINGS_ERROR';
export const SAVED_RACE_SETTINGS = 'SAVED_RACE_SETTINGS';

export const SAVING_RACE_RESULT = 'SAVING_RACE_RESULT';
export const SAVING_RACE_RESULT_ERROR = 'SAVING_RACE_RESULT_ERROR';
export const SAVED_RACE_RESULT = 'SAVED_RACE_RESULT';

export const FETCHING_RACE_SETTINGS = 'FETCHING_RACE_SETTINGS';
export const FETCHING_RACE_SETTINGS_ERROR = 'FETCHING_RACE_SETTINGS_ERROR';
export const RECEIVED_RACE_SETTINGS = 'RECEIVED_RACE_SETTINGS';

export const FETCHING_RACE_RESULT = 'FETCHING_RACE_RESULT';
export const FETCHING_RACE_RESULT_ERROR = 'FETCHING_RACE_RESULT_ERROR';
export const DELETED_RACE_RESULT = 'DELETED_RACE_RESULT';

export const DELETING_RACE_RESULT = 'DELETING_RACE_RESULT';
export const DELETING_RACE_RESULT_ERROR = 'DELETING_RACE_RESULT_ERROR';
export const RECEIVED_RACE_RESULT = 'RECEIVED_RACE_RESULT';
// Stage status
export const CHANGING_STAGE_STATUS = 'CHANGING_STAGE_STATUS';
export const CHANGING_STAGE_STATUS_ERROR = 'CHANGING_STAGE_STATUS_ERROR';
export const CHANGED_STAGE_STATUS = 'CHANGED_STAGE_STATUS';
// Send websocket message
export function wsStatus(status) {
	return {
		type: WS_STATUS,
		status,
	}
}

// Mention a participant
export function mentionParticipant(handle) {
	return {
		type: MENTION_PARTICIPANT,
    handle,
	}
}

export function sendMessage(data) {
	return (dispatch, getState) => {
		const states = getState();
		// Going to dispatch
		return dispatch((dispatch) => {
			sendMsg(states.rooms, data, dispatch);
		});
	}
}

export function joinRoom(roomId) {
	return (dispatch, getState) => {
    const states = getState();

    if (states.overall.roomId !== roomId) {
      console.log('gooing to register new', roomId);
      dispatch(register(roomId));
      return dispatch((dispatch) => {
        registerRoom(roomId);
      });
    } else {
      console.log('its already in the room');
      return false;
    }
	}
}

export function newMessage(message) {
  let type = null;
	switch(message.key) {
		case 'location':
      type = NEW_LOCATION;
			break;
    case 'result':
      type = NEW_RESULT;
      break;
    case 'situations':
      type = NEW_SITUATIONS;
      break;
    case 'map':
      type = NEW_MAP;
      break;
    case 'post':
      type = NEW_POST;
      break;
		default:
      // Todo do something about this
      type = UNKNOW_MESSAGE;
	}

  return {
    type,
    message,
  }
}

export function messageChunk(data) {
  return {
    type: MESSAGE_CHUNK,
    data,
  }
}

export function register(roomId) {
	return {
		type: REGISTERED,
		roomId,
	}
}

// Toggle menu
export function toggleMenu() {
  return {
    type: TOGGLE_MENU,
  }
}

export function toggleSecondMenu() {
  return {
    type: TOGGLE_SECOND_MENU,
  }
}

export function getRaces() {
	return (dispatch, getState) => {
    const requestObj = new Request(
      API_ENDPOINT + '/coll/race?multi=true',
      {
        method: 'GET',
        credentials: 'include',
      },
    );
		return dispatch(doFetch('getRaces', requestObj));
	}
}

export function getSettings(type, slug) {
  const actionType = `get${_.capitalize(type)}Settings`;
	return (dispatch, getState) => {
    // Change the request
    dispatch(preFetch(actionType));

    // Get the url
    let url = API_ENDPOINT + `/coll/${type}?slug=${slug}`;

    // If its stage, then we need to get the race id
    if (type === 'stage') {
      const state = getState();
      if (state.race.settings._id) {
        url += `&raceId=${state.race.settings._id}`;
      }
    }

    // Request Object
    const requestObj = new Request(
      url,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    var afterFetch = function(payload) {
      const settings = payload.data;
      console.log('this is the then in getSettings', getState(), settings);
      dispatch(changeBarTitle(settings.name));
    }

		dispatch(doFetch(actionType, requestObj, afterFetch));
	}
}

export function getUser(userName) {
  const actionType = 'getUser';
	return (dispatch, getState) => {
    // Change the request
    dispatch(preFetch(actionType));

    // Get the url
    let url = API_ENDPOINT + `/user/${userName}`;

    // Request Object
    const requestObj = new Request(
      url,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

    var afterFetch = function(payload) {
      const user = payload.data;
      console.log('after fetcing the user', user);
      //dispatch(changeBarTitle(user.name));
    }

		dispatch(doFetch(actionType, requestObj, afterFetch));
	}
}

/**
 * Get Rider
 */
export function getParticipant(handle) {
  const actionType = 'getParticipant';
	return (dispatch, getState) => {

    // Check the current state
    let state = getState();
    if (state.overall.participants[handle]) {
      console.log('Its already in there, so do nothing');
      return true;
    }

    // Change the request
    dispatch(preFetch(actionType));

    // Get the url
    let url = API_ENDPOINT + `/rider/${handle}`;

    // Request Object
    const requestObj = new Request(
      url,
      {
        method: 'GET',
        credentials: 'include',
      },
    );

		dispatch(doFetch(actionType, requestObj));
	}
}

// Change bar title
export function changeBarTitle(title) {
  return {
    type: CHANGE_BAR_TITLE,
    title,
  }
}

export function preFetch(type, data) {
	switch(type) {
		case 'saveRaceSettings':
			return {
				type: SAVING_RACE_SETTINGS,
        data,
			}
		case 'saveStageSettings':
			return {
				type: SAVING_STAGE_SETTINGS,
        data,
			}
		case 'getUser':
			return {
				type: FETCHING_USER,
        data,
			}
		case 'getRaceSettings':
			return {
				type: FETCHING_RACE_SETTINGS,
        data,
			}
		case 'getStageSettings':
			return {
				type: FETCHING_STAGE_SETTINGS,
        data,
			}
    case 'getRaces':
      return {
        type: FETCHING_RACES,
        data,
      }
    case 'addRace':
      return {
        type: ADDING_RACE,
        data,
      }
    case 'addStage':
      return {
        type: ADDING_STAGE,
        data,
      }
    case 'changingStageStatus':
      return {
        type: CHANGING_STAGE_STATUS,
        data,
      }
    case 'saveRaceResult':
      return {
        type: SAVING_RACE_RESULT,
        data,
      }
    case 'saveStageResult':
      return {
        type: SAVING_STAGE_RESULT,
        data,
      }
    case 'deleteStageResult':
      return {
        type: DELETING_STAGE_RESULT,
        data,
      }
    case 'deleteRaceResult':
      return {
        type: DELETING_RACE_RESULT,
        data,
      }
    case 'getRaceResult':
      return {
        type: FETCHING_RACE_RESULT,
        data,
      }
    case 'getStageResult':
      return {
        type: FETCHING_STAGE_RESULT,
        data,
      }
    default:
      return {
        type: FETCHING,
        data,
      };
	}
}

export function fetched(type, payload) {
	switch(type) {
		case 'saveRaceSettings':
			return {
				type: SAVED_RACE_SETTINGS,
        data: payload.data,
			}
		case 'saveStageSettings':
			return {
				type: SAVED_STAGE_SETTINGS,
        data: payload.data,
			}
		case 'getRaceSettings':
			return {
				type: RECEIVED_RACE_SETTINGS,
        data: payload.data,
			}
		case 'getStageSettings':
			return {
				type: RECEIVED_STAGE_SETTINGS,
        data: payload.data,
			}
    case 'getRaces':
      return {
        type: RECEIVED_RACES,
        data: payload.data,
      }
    case 'addRace':
      return {
        type: ADDED_RACE,
        data: payload.data,
      }
    case 'addStage':
      return {
        type: ADDED_STAGE,
        data: payload.data,
      }
    case 'changedStageStatus':
      return {
        type: CHANGED_STAGE_STATUS,
        data: payload.data,
      }
    case 'saveRaceResult':
      return {
        type: SAVED_RACE_RESULT,
        data: payload.data,
      }
    case 'saveStageResult':
      return {
        type: SAVED_STAGE_RESULT,
        data: payload.data,
      }
    case 'deleteStageResult':
      return {
        type: DELETED_STAGE_RESULT,
        data: payload.data,
      }
    case 'deleteRaceResult':
      return {
        type: DELETED_RACE_RESULT,
        data: payload.data,
      }
    case 'getRaceResult':
      return {
        type: RECEIVED_RACE_RESULT,
        data: payload.data,
      }
    case 'getStageResult':
      return {
        type: RECEIVED_STAGE_RESULT,
        data: payload.data,
      }
    case 'getParticipant':
      return {
        type: RECEIVED_PARTICIPANT,
        data: payload.data,
      }
    case 'getUser':
      return {
        type: RECEIVED_USER,
        data: payload.data,
      }
    default:
      return {
        type: FETCHED,
        payload,
      };
	}
}

export function fetchError(type, data) {
	switch(type) {
		case 'saveRaceSettings':
			return {
				type: SAVING_RACE_SETTINGS_ERROR,
        data,
			}
		case 'saveStageSettings':
			return {
				type: SAVING_STAGE_SETTINGS_ERROR,
        data,
			}
		case 'getRaceSettings':
			return {
				type: FETCHING_RACE_SETTINGS_ERROR,
        data,
			}
		case 'getStageSettings':
			return {
				type: FETCHING_STAGE_SETTINGS_ERROR,
        data,
			}
    case 'getRaces':
      return {
        type: FETCHING_RACES_ERROR,
        data,
      }
    case 'addRace':
      return {
        type: ADDING_RACE_ERROR,
        data,
      }
    case 'addStage':
      return {
        type: ADDING_STAGE_ERROR,
        data,
      }
    case 'saveRaceResult':
      return {
        type: SAVING_RACE_RESULT_ERROR,
        data,
      }
    case 'saveStageResult':
      return {
        type: SAVING_STAGE_RESULT_ERROR,
        data,
      }
    case 'deleteRaceResult':
      return {
        type: DELETING_RACE_RESULT_ERROR,
        data,
      }
    case 'deleteStageResult':
      return {
        type: DELETING_STAGE_RESULT_ERROR,
        data,
      }
    case 'getRaceResult':
      return {
        type: FETCHING_RACE_RESULT_ERROR,
        data,
      }
    case 'getStageResult':
      return {
        type: FETCHING_STAGE_RESULT_ERROR,
        data,
      }
    case 'changingStageStatus':
      return {
        type: CHANGING_STAGE_STATUS_ERROR,
        data,
      }
    case 'getUser':
      return {
        type: FETCHING_USER_ERROR,
        data,
      }
    default:
      return {
        type: FETCH_ERROR,
        data
      };
	}
}

function doFetch(type, requestObj, cb) {
	return dispatch => {
    // Do a fetch
		fetch(requestObj).then((response) => {
			return response.json();
		}).then((payload) => {
			console.log('payload sent', payload);

      if (payload.success) {
        dispatch(fetched(type, payload));
      } else {
        dispatch(fetchError(type, payload.msg));
      }

      // Execute the cb
      if (cb && payload.success) {
        cb(payload);
      }
		}).catch((error) => {
			console.log("There is an error while doing a fetch object", error, type, requestObj);
      dispatch(fetchError(type, `Sorry can not fetch settings for ${type}`));
		})
	}
}
