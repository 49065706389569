import React, { Component } from 'react';

import { RouteWithSubRoutes } from '../Routes.js';
import StageLive from '../components/StageLive.js';

class StageWrapper extends Component {

	// Render the component
  render() {
		const {
      stage,
			routes,
			match,
      getSettings,
		} = this.props;

    console.log('stage home props', this.props, stage);
    let content = '';
    const stageSlug = match.params.stageSlug;
    if (stage.settings.slug !== stageSlug) {
      // If its not fetching or failed, then go and get settings
      if (['FETCHING', 'FAILED'].indexOf(stage.request.status) === -1) {
        // Fetch settings
        getSettings(stageSlug);
      }

      if (stage.request.status === 'FETCHING') {
        content = <div>Loading</div>
      }

      if (stage.request.status === 'FAILED') {
        content = <div>Failed: {stage.request.msg}</div>
      }
    } else if (match.isExact) {
      console.log('its exact match');
      content = <StageLive stage={this.props.stage}/>
    } else {
      console.log('with all the routes');
      // Everything is fine output
      // Output a loading if need fetching
      content = routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route}/>
      ));
    }

    // Join room
    if (Object.keys(stage.settings).length > 0) {
      this.props.joinRoom(stage.settings);
    }

		// Render the tags
    return (
      <div className="stage-wrapper">
        {content}
      </div>
    );
  }
}

export default StageWrapper;
