import React from 'react';
import moment from 'moment';

const Info = ({ info }) => {
  if (!info || !info.startDate) {
    return (<div></div>)
  }
  return (
    <div className="stage-info">
      <h2>{ info.name }</h2>

      {info.status &&
        <p>Status: {info.status}</p>
      }

      <p>Start Date: {moment(info.startDate).format('LLL')}</p>

      {info.startLocation &&
        <p>Start Location: {info.startLocation.name}</p>
      }

      {info.endLocation &&
        <p>End Location: {info.endLocation.name}</p>
      }

      {info.distance &&
        <p>Distance: {info.distance}</p>
      }

      <div dangerouslySetInnerHTML={{__html: info.description}} />
    </div>
  );
}

export default Info;
