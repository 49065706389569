import {
  REGISTERED,
  WS_STATUS,
  TOGGLE_MENU,
  TOGGLE_SECOND_MENU,
  CHANGE_BAR_TITLE,
  RECEIVED_USER,
  RECEIVED_RACES,
  RECEIVED_PARTICIPANT,
  MENTION_PARTICIPANT,
} from '../actions.js';

// Initial request object
const initRequestObj = {
  status: '',
  msg: '',
}

// The initial state
let initialState = {
  barTitle: '',
	registered: false,
	roomId: null,
	wsStatus: null,
	menuOpen: false,
  secondMenuOpen: false,
  participants: {},
  mentionedParticipant: null,
  races: [],
  user: {},
  userRequest: initRequestObj,
};

export default (state = initialState, action) => {
  switch (action.type) {
		case CHANGE_BAR_TITLE:
			return Object.assign({}, state, {
        barTitle: action.title,
			});
		case REGISTERED:
			return Object.assign({}, state, {
				registered: true,
        roomId: action.roomId,
			});
		case WS_STATUS:
			return Object.assign({}, state, {
				wsStatus: action.status,
			});
		case TOGGLE_MENU:
			return Object.assign({}, state, {
				menuOpen: !state.menuOpen,
			});
		case TOGGLE_SECOND_MENU:
			return Object.assign({}, state, {
				secondMenuOpen: !state.secondMenuOpen,
			});
		case RECEIVED_USER:
			return Object.assign({}, state, {
        user: action.data,
			});
		case RECEIVED_RACES:
			return Object.assign({}, state, {
        races: action.data,
			});
		case RECEIVED_PARTICIPANT:
      let participants = state.participants;
      participants[action.data.handle] = action.data;
			return Object.assign({}, state, {
        participants,
			});
		case MENTION_PARTICIPANT:
			return Object.assign({}, state, {
        mentionedParticipant: action.handle,
			});
    default:
      return state
  }
}
