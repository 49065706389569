import React, { Component } from 'react';

import { RouteWithSubRoutes } from '../Routes.js';
import Stages from '../containers/Stages.js';

class RaceWrapper extends Component {

  componentWillUnmount() {
    // Reset the request status
    // So it isn't always display failed if there is connection error
    console.log('going to reset the request stuff');
  }

	// Render the component
  render() {
		const {
      race,
			routes,
			match,
      getSettings,
		} = this.props;

    let content = '';
    const raceSlug = match.params.raceSlug;
    if (race.settings.slug !== raceSlug) {
      // If its not fetching or failed, then go and get settings
      if (['FETCHING', 'FAILED'].indexOf(race.request.status) === -1) {
        // Fetch settings
        getSettings(raceSlug);
      }

      if (race.request.status === 'FETCHING') {
        content = <div>Loading</div>
      }

      if (race.request.status === 'FAILED') {
        content = <div>Failed: {race.request.msg}</div>
      }
    } else if (match.isExact) {
      console.log('its exact match');
      content = <Stages />
    } else {
      console.log('with all the routes');
      // Everything is fine output
      // Output a loading if need fetching
      content = routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route}/>
      ));
    }

    // todo: maybe also check if match exactly, then just output other contents

    console.log("race wrapper match", match);
		// Render the tags
    return (
      <div className="race-wrapper">
        {content}
      </div>
    );
  }
}

export default RaceWrapper;
