import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Tab,
  Tabs,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import StageIcon from '@material-ui/icons/List';

import Info from './Info.js';
import CoverImage from './CoverImage.js';
import TabContainer from './TabContainer.js';

class Stages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
    }

    this.getTabContent = this.getTabContent.bind(this);
    this.tabChanged = this.tabChanged.bind(this);
  }

  tabChanged(event, value) {
    this.setState({
      currentTab: value,
    })
  }

  getTabContent() {
    const {
      currentTab,
    } = this.state;

    const {
      stages,
      settings,
    } = this.props.race;

    const { userName } = this.props;

    if (currentTab === 0) {
      return (
        <List>
        { stages.map((stage) => (
          <ListItem key={stage.slug} button>
            <ListItemAvatar>
              <Avatar alt={stage.name} src={stage.coverImageUrl} />
            </ListItemAvatar>

            <ListItemText
              primary={(
                <Link to={`/u/${userName}/races/${settings.slug}/stages/${stage.slug}`}>
                  { stage.name }
                </Link>
              )}
              secondary={
                <React.Fragment>
                  {stage.startLocation && (
                    <span>Start: {stage.startLocation.name}</span>
                  )}

                  {stage.endLocation && (
                    <span>Finish: {stage.endLocation.name}</span>
                  )}

                  <br />
                  {stage.startDate && (
                    <span>Date: {stage.startDate}</span>
                  )}

                  {stage.distance && (
                    <span>distance: {stage.distance}</span>
                  )}
                </React.Fragment>
              }
            />
          </ListItem>
        )) }
        </List>
      )
    } else {
      return (
        <Info info={settings} />
      )
    }
  }

  render() {
    const {
      currentTab,
    } = this.state;

    // Get the stage and settings out
    const {
      settings,
    } = this.props.race;

    const tabLabels = [];
    tabLabels.push(<Tab key={0} icon={<StageIcon />} label="Stages" />);
    // Add the info
    tabLabels.push(<Tab key={1} icon={<InfoIcon />} label="Info" />);

    const tabContent = this.getTabContent();
    // Todo add the race name as well
    return (
      <div>
        <CoverImage src={settings.coverImageUrl} />

        <Tabs
          variant="fullWidth"
          value={currentTab}
          onChange={this.tabChanged.bind(this)}
          className="tab-container"
          textColor="secondary"
        >
          {tabLabels}
        </Tabs>

        <TabContainer>
          {tabContent}
        </TabContainer>
      </div>
    )
  }
}

export default Stages;
