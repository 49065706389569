import React, { Component } from 'react';

// Styling
import { List, ListItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

class Result extends Component {
  render() {
    let {
      name,
      type,
      description,
      entries,
      data,
    } = this.props.data;

    // Backward compat
    if (!name) {
      let {
        name,
        type,
        description,
        entries,
      } = data;
    }

    return (
      <div>
        <h2>{ name }</h2>
        <p>type: { type }</p>

        <div>{ description }</div>

        <List>
        {entries.map((item) => (
          <ListItem
            key={item.handle}
            leftAvatar={
              <Avatar
                src={item.avatar}
                size={30}
              />
            }
          >
            {item.name} - {item.position} {item.time} {item.desc}
          </ListItem>
        ))}
        </List>
      </div>
    )
  }
}

export default Result;
