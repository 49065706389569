import React, { Component } from 'react';
import BasicInfo from './Info.js';
import Posts from '../Posts.js';
import LiveFeeds from './LiveFeeds.js';
import _ from 'lodash';

class Stats extends Component {
	// Render the component
  render() {
    const { participant, posts } = this.props;

    return (
      <div className="participant-stats">

        { participant && <BasicInfo data={participant} /> }

        <LiveFeeds />

        <div className="contents">
          <h3>What's happening so far</h3>
          <Posts posts={posts} clickMention={() => 1} />
        </div>

      </div>
    );
  }
}

export default Stats;
