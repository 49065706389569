import { connect } from 'react-redux';
import UserWrapperCom from '../components/UserWrapper.js';

// Actions
import {
  getUser,
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
    user: state.overall.user,
    userRequest: state.overall.userRequest,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (userName) => {
			dispatch(getUser(userName));
		},
	}
}

// Connect the props to components
const UserWrapper = connect(
	mapStateToProps,
	mapDispatchToProps,
)(UserWrapperCom);

// Export the module
export default UserWrapper;

