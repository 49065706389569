import {
  FETCHING_RACE_SETTINGS,
  FETCHING_RACE_SETTINGS_ERROR,
  RECEIVED_RACE_SETTINGS,
} from '../actions.js';

const initRequestObj = {
  status: '',
  msg: '',
}

// The initial state
let initialState = {
  raceId: null,
  stages:[],
  startList: [],
  results: [],
  settings: {},
  request: initRequestObj,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_RACE_SETTINGS:
			return Object.assign({}, state, {
        request: {
          status: 'FETCHING',
          msg: '',
        },
			});
    case FETCHING_RACE_SETTINGS_ERROR:
			return Object.assign({}, state, {
        request: {
          status: 'FAILED',
          msg: action.data,
        }
			});
    case RECEIVED_RACE_SETTINGS:
			return Object.assign({}, state, {
        request: initialState.request,
        stages: action.data.stages || [],
        results: action.data.results || [],
				settings: action.data,
        startList: action.data.startList || [],
			});
    default:
      return state
  }
}
