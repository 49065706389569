import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  coverImage: {
    maxWidth: "100%",
    //height: "300px",
  }
}

const CoverImage = ({ src, alt, classes }) => {
  if (!src) {
    return null;
  }

  // Output the cover image
  return (
    <div>
      <img className={classes.coverImage} src={src} alt={alt} />
    </div>
  )
}

export default withStyles(styles)(CoverImage);
