import { combineReducers } from 'redux';
import overall from './overall.js';
import stage from './stage.js';
import race from './race.js';

const App = combineReducers({
  overall,
  stage,
  race,
})

export default App;
