import React, { Component } from 'react';
import Situation from './Situation.js';

// Styling
class Situations extends Component {
  render() {
    console.log('properties of situations', this.props);
    if (!this.props.situations) {
      return <div/>
    }
    const items = this.props.situations.map((situation) => (
      <Situation
        key={situation.id}
        data={situation}
        clickMention={this.props.clickMention}
      />
    ));

    return (
      <div className="situation-container">
        <h2>Situations</h2>
        { items }
      </div>
    )
  }
}

export default Situations;
