import { connect } from 'react-redux';
import CourseMapCom from '../components/CourseMap.js';
import {
  useLatest,
  topicNameFromStage,
} from '../share/utils.js';

const mapStateToProps = (state) => {
  // Take the draw map from the maps
  const topicName = topicNameFromStage(state.stage.settings);
  let drawData = false;
  if (state.stage.maps[topicName]) {
		drawData = useLatest(state.stage.maps[topicName]).data;
  }

  let courseGeo = false;
  if (state.stage.settings.courseGeo && state.stage.settings.courseGeo.course) {
    courseGeo = state.stage.settings.courseGeo.course;
  }

	return {
    courseGeo,
    drawData,
	}
}

// Connect the props to components
const CourseMap = connect(
	mapStateToProps
)(CourseMapCom);

// Export the module
export default CourseMap;
