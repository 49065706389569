import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';

import Chat from './components/Chat.js';
import AppBar from './containers/AppBar.js';
import Drawers from './containers/Drawers.js';
// Styling
import {
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

// Router
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import ws from './store/ws.js';
import Routes, { RouteWithSubRoutes } from './Routes.js';

//Initialise the websocket
ws(store);

// Routes

// Theme
const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: blue,
  },
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <div className="App">
              <AppBar />
              <Drawers />

              {Routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
              <Chat />
            </div>
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
