// @flow
import React from 'react';
import _ from 'lodash';
import LiveMarker from './LiveMarker.js';
import LiveLine from './LiveLine.js';

// Live layer
const LiveLayer = ({ locations }) => {
  console.log('live layer', locations);

  let show = true;
  if (locations.length < 1) {
    show = false;
  }

  // Sorting the locations
  const polyline = _.sortBy(locations, 'timestamp').map(l => {
    // Only return if the data is valid
    const data = l.data;
    if (data[0] && data[1]) {
      return data;
    } else {
      return false;
    }
  }).filter(d => d);


  if (polyline.length < 1) {
    show = false;
  }

  if (!show) {
    return null;
  }

  console.log('location polyline', polyline);
  const currentPos = _.last(polyline);

  if (polyline.length === 0 && currentPos.length === 0) {
    return false;
  }

  return <div className="live-layer">
      <LiveMarker pos={currentPos} />
      <LiveLine positions={polyline} color="orange" />
  </div>
}

export default LiveLayer;
