import { connect } from 'react-redux';
import DrawsCom from '../components/Drawers.js';

// Actions
import {
  toggleMenu,
  toggleSecondMenu,
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
    race: state.race,
    stage: state.stage,
    menuOpen: state.overall.menuOpen,
		secondMenuOpen: state.overall.secondMenuOpen,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleMenu: () => {
			console.log('going to goggle main menu');
			dispatch(toggleMenu());
		},
		toggleSecondMenu: () => {
			console.log('going to toggle secondary menu');
			dispatch(toggleSecondMenu());
		},
	}
}

// Connect the props to components
const Draws = connect(
	mapStateToProps,
	mapDispatchToProps,
)(DrawsCom);

// Export the module
export default Draws;
