import {
  NEW_POST,
  NEW_RESULT,
  NEW_LOCATION,
  NEW_MAP,
  NEW_SITUATIONS,
  MESSAGE_CHUNK,
  FETCHING_STAGE_SETTINGS,
  FETCHING_STAGE_SETTINGS_ERROR,
  RECEIVED_STAGE_SETTINGS,
} from '../actions.js';
import _ from 'lodash';
import {
  normaliseData,
  handleAddMerge,
  handleMessages,
  topicNameFromStage,
} from '../../share/utils.js';

// Initial request object
const initRequestObj = {
  status: '',
  msg: '',
}

// The initial state
let initialState = {
	locations: {},
	posts: {},
  results: {},
  situations: {},
  maps: {},
  startLists: {},
  settings: {},
  request: initRequestObj,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NEW_POST:
      return Object.assign({}, state, handleMessages(_.pick(state, ['posts']), [action.message]));
    case NEW_SITUATIONS:
      return Object.assign({}, state, handleMessages(_.pick(state, ['situations']), [action.message]));
    case NEW_RESULT:
      return Object.assign({}, state, handleMessages(_.pick(state, ['results']), [action.message]));
    case NEW_MAP:
      return Object.assign({}, state, handleMessages(_.pick(state, ['maps']), [action.message]));
    case NEW_LOCATION:
      return Object.assign({}, state, handleMessages(_.pick(state, ['locations']), [action.message]));
    case MESSAGE_CHUNK:
      const oldState = _.pick(state, ['maps', 'posts', 'situations', 'results', 'locations']);
      let resultState = handleMessages(oldState, action.data);
      return Object.assign({}, state, resultState);
    case FETCHING_STAGE_SETTINGS:
			return Object.assign({}, state, {
        request: {
          status: 'FETCHING',
          msg: '',
        },
			});
    case FETCHING_STAGE_SETTINGS_ERROR:
			return Object.assign({}, state, {
        request: {
          status: 'FAILED',
          msg: action.data,
        }
			});
		case RECEIVED_STAGE_SETTINGS:
			return Object.assign({}, state, receivedStageSettings(state, action));
    default:
      return state
  }
}

// Handle receive settings
function receivedStageSettings(state, action) {
  // Get the topic name from stage
  const topicName = topicNameFromStage(action.data);

  let startLists = state.startLists;
  if (!_.isEmpty(action.data.startList)) {
    startLists[topicName] = action.data.startList;
  }

  // Handle the results - special care
  let results = state.results;
  if (action.data.results && action.data.results.length > 0) {
    results[topicName] = action.data.results.map((result) => {
      return normaliseData(result);
    });
  }

  let drawData = null;
  if (action.data.courseGeo && action.data.courseGeo.drawData) {
    drawData = action.data.courseGeo.drawData;
  }

  return {
    request: initialState.request,
    results,
    //results: handleAddMerge('results', state, topicName, normaliseData(action.data.results)),
    maps: handleAddMerge('maps', state, topicName, normaliseData(drawData)),
    situations: handleAddMerge('situations', state, topicName, normaliseData(action.data.situations)),
    settings: action.data,
    startLists,
  };
}
