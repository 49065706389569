import { connect } from 'react-redux';
import StageWrapperCom from '../components/StageWrapper.js';

// Actions
import {
  getSettings,
  joinRoom,
} from '../store/actions.js';

import {
  topicNameFromStage,
} from '../share/utils.js';

const mapStateToProps = (state) => {
	return {
    stage: state.stage,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getSettings: (slug) => {
			dispatch(getSettings('stage', slug));
		},
    joinRoom: (stage) => {
      dispatch(joinRoom(topicNameFromStage(stage)));
    }
	}
}

// Connect the props to components
const StageWrapper = connect(
	mapStateToProps,
	mapDispatchToProps,
)(StageWrapperCom);

// Export the module
export default StageWrapper;
