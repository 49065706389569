import React, { Component } from 'react'
import { LayersControl, FeatureGroup, Circle, Map, TileLayer, Marker, Popup } from 'react-leaflet'
import FullscreenControl from 'react-leaflet-fullscreen';
import L from 'leaflet';
import 'react-leaflet-fullscreen/dist/styles.css'
import 'leaflet.elevation/dist/Leaflet.Elevation-0.0.2.src.js';
import 'leaflet.elevation/dist/Leaflet.Elevation-0.0.2.css';
import './CourseMap.css';

import LiveLayer from '../containers/LiveLayer.js';

const cartoDBTiles = 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
const mapAttr = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
//const osmTiles ='http://{s}.tile.osm.org/{z}/{x}/{y}.png';

function calculateBounding(latLngs) {
	const bounds = L.latLngBounds(latLngs.map((c) => {
		return [c[1], c[0]];
	}));

	return bounds;
}

var el = L.control.elevation({
  	position: "bottomleft",
	theme: "steelblue-theme", //default: lime-theme
	width: 400,
	height: 125,
	margins: {
		top: 10,
		right: 30,
		bottom: 30,
		left: 50
	},
	useHeightIndicator: true, //if false a marker is drawn at map position
	interpolation: "linear", //see https://github.com/mbostock/d3/wiki/SVG-Shapes#wiki-area_interpolate
	hoverNumber: {
		decimalsX: 3, //decimals on distance (always in km)
		decimalsY: 0, //deciamls on hehttps://www.npmjs.com/package/leaflet.coordinatesight (always in m)
		formatter: undefined //custom formatter function may be injected
	},
	xTicks: undefined, //number of ticks in x axis, calculated by default according to width
	yTicks: undefined, //number of ticks on y axis, calculated by default according to height
	collapsed: false,  //collapsed mode, show chart on click or mouseover
	imperial: false    //display imperial units instead of metric
});

function featureContainElevation(feature) {
  let result = false;
  if (feature.geometry) {
    if (feature.geometry.type === 'LineString') {
      if (feature.geometry.coordinates) {
        // Every coordinate must have 3 values - lon, lat, elevation/altitude
        result = feature.geometry.coordinates.every((coord) => {
          return coord.length === 3;
        });
      }
    }
  }

  return result;
}

// Added elevation
let addedElevation = false;
class MapContainer extends Component {
  state = {
    lat: -44.696476296,
    lng: 169.13693,
    zoom: 13,
  }

  addElevation(leafletMap) {
    // For if the map is zoom to the bounding or not
    let showOverall = false;

    if (this.props.courseGeo) {
      el.addTo(leafletMap);
      const courseGeo = L.geoJson(this.props.courseGeo, {
        onEachFeature: (feature, layer) => {
          console.log('feature and layer', feature, layer);
          // Check for the geometry and only add the elevation map if its line string and contain elevation data
          if (!addedElevation && featureContainElevation(feature)) {
            console.log('going to add elevation');
            el.addData.call(el, feature, layer);

            addedElevation = true;
          }
          console.log("going to add content");
          layer.bindPopup("This is the stage route");
        }
      });
      courseGeo.addTo(leafletMap);

      // Zoom th map, so its able to see everything
      leafletMap.fitBounds(courseGeo.getBounds());
      console.log("component did mount this leaflet element", leafletMap);
      console.log(leafletMap.getZoom());
      showOverall = true;
    }

    // Also add the drawed map as well
    if (this.props.drawData) {
      console.log('going to add drawing to the map', this.props.drawData);
      const drawLayer = L.geoJson(this.props.drawData, {
        onEachFeature: function(feature, layer) {
          console.log('drawing feature', feature);
          let title = '';
          if (feature.properties && feature.properties.Title) {
            title = feature.properties.Title;
          }

          let content = '';
          if (feature.properties && feature.properties.Content) {
            content = feature.properties.Content;
          }

          layer.bindPopup(`<h2>${title}</h2><p>${content}</p>`);
        }
      });

      // Add to drawing layer to the map
      drawLayer.addTo(leafletMap);

      // Zoom the map, so it can see everything for the drawing layer
      if (!showOverall) {
        leafletMap.fitBounds(drawLayer.getBounds());
        console.log("component did mount this leaflet element", leafletMap);
        console.log(leafletMap.getZoom());
      }
    }

  }
  componentDidMount() {
    const leafletMap = this.leafletMap.leafletElement;
    console.log("did mount course map component", this.props);

    // Add the elevation to the map
    this.addElevation(leafletMap);
  }

  componentDidUpdate() {
    const leafletMap = this.leafletMap.leafletElement;
    console.log("did update course map component", leafletMap);

    // Add the elevation to the map
    this.addElevation(leafletMap);
  }

  render() {
    console.log("course map component", this.props);
    const position = [this.state.lat, this.state.lng]
    return (
      <Map ref={m => { this.leafletMap = m; }} center={position} zoom={this.state.zoom}>
        <TileLayer
          attribution={mapAttr}
          url={cartoDBTiles}
        />
				<FullscreenControl position="topleft" />
      <LiveLayer />
<LayersControl position='topright'>
  <LayersControl.BaseLayer name='OpenStreetMap.BlackAndWhite'>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url='http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png'
    />
  </LayersControl.BaseLayer>
  <LayersControl.BaseLayer name='OpenStreetMap.Mapnik'>
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
    />
  </LayersControl.BaseLayer>
  <LayersControl.Overlay name='Marker with popup'>
    <Marker position={[51.51, -0.06]}>
      <Popup>
        <span>A pretty CSS3 popup. <br/> Easily customizable.</span>
      </Popup>
    </Marker>
  </LayersControl.Overlay>
  <LayersControl.Overlay name='Feature group'>
    <FeatureGroup color='purple'>
      <Popup>
        <span>Popup in FeatureGroup</span>
      </Popup>
      <Circle center={[51.51, -0.06]} radius={200} />
    </FeatureGroup>
  </LayersControl.Overlay>
</LayersControl>
      </Map>
    )
  }
}

export default MapContainer
