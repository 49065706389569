import { connect } from 'react-redux';
import LiveLayerCom from '../components/LiveLayer.js';
import {
  topicNameFromStage,
} from '../share/utils.js';

const mapStateToProps = (state) => {
  // Figure out the locations
  let locations = [];
  const topicName = topicNameFromStage(state.stage.settings);
  if (state.stage.locations[topicName]) {
		locations = state.stage.locations[topicName];
  }

	return {
    locations,
	}
}

// Connect the props to components
const LiveLayer = connect(
	mapStateToProps
)(LiveLayerCom);

// Export the module
export default LiveLayer;
