import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import _ from 'lodash';
import { ListItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

class ListTable extends React.PureComponent {
  render () {
    console.log('the data stuff', this.props);
      // Configure the columns
    const columns = [
		{
			Header: 'Name',
			accessor: 'name',
			Cell: row => {
        let listItemProps = {
          primaryText: row.value,
        }

        // Add avatar
        if (row.original.avatar) {
          listItemProps.leftAvatar = <Avatar src={row.original.avatar} />;
        }

        return <ListItem
                {...listItemProps}
              />
			}
		},
		{
			Header: 'Team',
			id: 'team',
			accessor: 'team',
		},
		{
			Header: 'Age',
			accessor: 'age',
		},
		{
			Header: 'Bib',
			accessor: 'bib',
		},
	];

    return (
      <div>
        <div className='table-wrap'>
          <ReactTable
            filterable={true}
            className='-striped -highlight'
            data={this.props.data}
            columns={columns}
            defaultPageSize={10}
          />
        </div>
        <div style={{textAlign: 'center'}}>
          <br />
          <em>Tip: Hold shift when sorting to multi-sort!</em>
        </div>
      </div>
    )
  }
}

export default ListTable;
