import { connect } from 'react-redux';
import AppBarCom from '../components/AppBar.js';

// Actions
import {
  toggleMenu,
  toggleSecondMenu,
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
    stageStatus: state.stage.settings.status,
		title: state.overall.barTitle,
    menuOpen: state.overall.menuOpen,
		secondMenuOpen: state.overall.secondMenuOpen,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		toggleMenu: () => {
			console.log('going to goggle main menu');
			dispatch(toggleMenu());
		},
		toggleSecondMenu: () => {
			console.log('going to toggle secondary menu');
			dispatch(toggleSecondMenu());
		},
	}
}

// Connect the props to components
const AppBar = connect(
	mapStateToProps,
	mapDispatchToProps,
)(AppBarCom);

// Export the module
export default AppBar;
