import React, { Component } from 'react';

import { RouteWithSubRoutes } from '../Routes.js';
import Races from '../containers/Races.js';

class UserWrapper extends Component {

  componentWillUnmount() {
    // Reset the request status
    // So it isn't always display failed if there is connection error
    console.log('going to reset the request stuff');
  }

	// Render the component
  render() {
		const {
      user,
      userRequest,
			routes,
			match,
      getUser,
      location,
      history,
		} = this.props;

    let content = '';
    const userName = match.params.userName;

    console.log('user name in the url', userName);
    if (user.userName !== userName) {
      console.log('need to do something')
      // If its not fetching or failed, then go and get settings
      if (['FETCHING', 'FAILED'].indexOf(userRequest.status) === -1) {
        console.log('get user');
        // Fetch user
        getUser(userName);
      }

      if (userRequest.status === 'FETCHING') {
        console.log('its fetching');
        content = <div>Loading</div>
      }

      if (userRequest.status === 'FAILED') {
        console.log('this is failed');
        content = <div>Failed: {userRequest.msg}</div>
      }
    } else if (match.isExact) {
      console.log('its exact match');
      content = <Races />
    } else {
      console.log('with all the routes');
      // Everything is fine output
      // Output a loading if need fetching
      content = routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route}/>
      ));
    }

  // Slice the last part of the path
  let previousPath = '/' + location.pathname.split('/').filter(p => p !== '').slice(0,-2).join('/');
  console.log('previous path', previousPath);

    // todo: maybe also check if match exactly, then just output other contents

    console.log("user wrapper match", match);
		// Render the tags
    return (
      <div className="user-wrapper">
        {content}
      </div>
    );
  }
}

export default UserWrapper;

