import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@material-ui/core';

class Races extends Component {
  render() {
    const {
      races,
      userName,
    } = this.props;


    // Todo add the race name as well
    return (
        <List>
        { races.map((race) => (
          <ListItem key={race.slug} button>
            <ListItemAvatar>
              <Avatar alt={race.name} src={race.coverImageUrl} />
            </ListItemAvatar>

            <ListItemText
              primary={(
                <Link to={`/u/${userName}/races/${race.slug}`}>
                  { race.name }
                </Link>
              )}
              secondary={
                <React.Fragment>
                  <span>Date: {race.startDate} distance: {race.distance}</span>
                </React.Fragment>
              }
            />
          </ListItem>
        )) }
        </List>
    )
  }
}

export default Races;
