import React, { Component } from 'react';
import {List, ListItem} from '@material-ui/core';
import {
  Link,
} from 'react-router-dom';

class Home extends Component {
  constructor(props) {
    super(props);

    // Get the races if there is non
    if (props.races.length === 0) {
      props.getRaces();
    }
  }

  changeTitle() {
    this.props.appBarTitle('Home');
  }

  componentWillMount() {
    this.changeTitle();
  }

  componentWillUpdate() {
    this.changeTitle();
  }

	// Render the component
  render() {
    const {
      races,
    } = this.props;

    // Filter out the races doesn't have owner
    let racesToDisplay = races.filter((item) => item.owner);

		// Render the tags
    return (
      <div>
        <List>
        { racesToDisplay.map((race) => (
          <ListItem key={race.slug}>
            <Link to={`/u/${race.owner.userName}/races/${race.slug}/`}>
              { race.name }
            </Link>
          </ListItem>
        )) }
        </List>
      </div>
    );
  }
}

export default Home;
