import React from 'react';

// Basic info
const Info = ({ data }) => {
  return (
    <div className="basic-info">
      <p>
        <img src={data.avatar} alt={data.name} />
        <br/>
        { data.name }
      </p>

      <p>Age: {data.age}</p>
      <p>Team: {data.team}</p>
      <p><img src={data.country.icon} alt={data.team} /></p>
    </div>
  )
}

export default Info;
