import React from 'react';
import Post from './Post.js';
import _ from 'lodash';

const Posts = ({ posts, clickMention }) => {
	return (
    <ul>
      {_.orderBy(posts, 'timestamp', 'desc').map(post =>
        <Post
          onClickMention={clickMention}
          key={post.timestamp}
          post={post}
        />
      )}
    </ul>
	)
}

export default Posts;
