// Server domain
let SERVER_DOMAIN = 'https://liveapi.cluelesscycling.com';

// API endpoint
let API_ENDPOINT = SERVER_DOMAIN;

// Socket.io option
let SOCKET_IO_OPT = {}

// Different end point when its not on production
if (process.env.NODE_ENV !== 'production') {
  SERVER_DOMAIN = API_ENDPOINT = 'http://localhost:5000';

  // No options for socket io
  SOCKET_IO_OPT = {};
}

export {
  SERVER_DOMAIN,
  API_ENDPOINT,
  SOCKET_IO_OPT,
}
