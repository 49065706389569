import {
  wsStatus,
  newMessage,
  messageChunk,
  register,
} from './actions.js';

import {
  SERVER_DOMAIN,
  SOCKET_IO_OPT,
} from '../share/config.js';

// Socket io
const io = require('socket.io-client');
const socket = io(SERVER_DOMAIN, SOCKET_IO_OPT);

export const CONNECTED = 'CONNECTED';
export const ERROR = 'ERROR';
export const DISCONNECTED = 'DISCONNECTED';

let store = null;

// Register the current connection
export function registerRoom(roomId) {
	console.log('register room', roomId);
		// Tell the ws our roomId
		socket.emit('register', {
			roomId
		}, (res) => {
			store.dispatch(register(roomId));
		});
}

// Send message
export function sendMsg(rooms, message, dispatch) {
		socket.emit('message', {
			rooms,
			payload: message,
		}, (res) => {
			console.log('msg ack:', res);
			// Dispatch an event
			//dispatch(register(true));
		});
}

function autoRejoinRoom() {
  // Get the latest room id
  const states = store.getState();
  if (states.overall.roomId) {
    const roomId = states.overall.roomId;
      // Check if already in the room
    socket.emit('inRoom', {
      roomId,
    }, (res) => {
      console.log('results from if we in the room or not', res);
      if (!res.inRoom) {
        console.log('going to join the room again');
        registerRoom(roomId);
      }
    });
  }
}

// Listen to event
export default (newStore) => {
	// Assign to global store
	store = newStore;

	// Handle events of the socket
	socket.on('connect', function(error, data) {
		console.log('socket connected', error, data);
		socket.emit('ack', {
			foo: 'foo'
		}, (res) => {
			console.log( 'acknowledgement:', res )
			store.dispatch(wsStatus(CONNECTED));
		})

		// Listen on the message
		socket.on('message', function(data) {
			console.log('in side ws There is a message on the socket', data);
			store.dispatch(newMessage(data));
		})

		// Listen on history
		socket.on('history', function(data) {
			console.log('in side ws There is histric data from socket', data);
			store.dispatch(messageChunk(data));
		})
	});

	socket.on('error', function(error, data) {
		store.dispatch(wsStatus(ERROR));
		console.log('socket error', error, data);
	});
	socket.on('disconnect', function(error, data) {
		store.dispatch(wsStatus(DISCONNECTED));
		console.log('socket discounnect', error, data);
	})
	socket.on('reconnect', function(error, data) {
		console.log('socket reconnected', error, data);
    console.log('going to auto rejion room');
    autoRejoinRoom();
	});
}
