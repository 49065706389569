// @flow
import {
  Path,
  withLeaflet,
} from 'react-leaflet'
import { polyline } from 'leaflet'

class LiveLine extends Path {
/*
  static propTypes = {
    children: PropTypes.children,
    positions: PropTypes.oneOfType([
      latlngListType,
      PropTypes.arrayOf(latlngListType),
    ]).isRequired,
  }
*/

  createLeafletElement(props: Object): Object {
console.log('live line', props);
    const { positions, ...options } = props
    return polyline(positions, this.getOptions(options))
  }

  updateLeafletElement(fromProps: Object, toProps: Object) {
console.log('live line going to update', fromProps, toProps);
    if (toProps.positions.length !== fromProps.positions.length) {
      this.leafletElement.setLatLngs(toProps.positions)
    }
    this.setStyleIfChanged(fromProps, toProps)
  }
}

export default withLeaflet(LiveLine);
