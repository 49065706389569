import React from 'react';
import {
  Drawer,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  AppBar,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { withRouter } from 'react-router';

// Router
import {
  Link,
} from 'react-router-dom';

import ParticipantStats from '../containers/participant/stats.js';

const Drawers = ({ race, stage, toggleMenu, toggleSecondMenu, menuOpen, secondMenuOpen, location, history }) => {

  // Figure out the current route and change the sidebar respectively
  let currentLocation = 'home';
  const path = location.pathname;
  if (path.match('/u/.*/races/.*/stages/.*')) {
    currentLocation = 'stage';
  } else if (path.match('/u/.*/races/.*/')) {
    currentLocation = 'race';
  }

  let secondMenuContent = <div></div>;
  if (secondMenuOpen) {
    secondMenuContent = <ParticipantStats />;
  }
  console.log("path", location);

  // Slice the last part of the path
  let previousPath = '/' + location.pathname.split('/').filter(p => p !== '').slice(0,-2).join('/');

  //const routes = Sidebar[currentLocation];
  //todo: need to replace the race name and stage name with the real one

  const goBack = () => {
    toggleMenu();

    history.push(previousPath);
  }

  // Get the drawer
	return (
    <div>
        <Drawer
          docked="false"
          open={menuOpen}
        >
          <IconButton onClick={e => toggleMenu()}>
            <ChevronLeftIcon />
          </IconButton>

          <Divider />

          {previousPath !== '/' &&
            <p onClick={() => goBack()}>
              Back
            </p>
          }
        </Drawer>

        <Drawer
          docked={false}
          width="60%"
          openSecondary={true}
          open={secondMenuOpen}
          onRequestChange={(e) => toggleSecondMenu()}
        >
          { secondMenuContent }
        </Drawer>
    </div>
	)
}

export default withRouter(Drawers);
