import { connect } from 'react-redux';
import StagesCom from '../components/Stages.js';

const mapStateToProps = (state) => {
	return {
    race: state.race,
    userName: state.overall.user.userName,
	}
}

// Connect the props to components
const Stages = connect(
	mapStateToProps,
)(StagesCom);

// Export the module
export default Stages;
