import _ from 'lodash';

function debugOutput(one, two, three) {
	//console.log(one, two, three);
}

// Add merge from data pipe to local
export function addMerge(data, newItem, uniqByTerm) {
  // Assign the data to items
  let items = data;

  // Add the new item to the items
  items = items.concat(newItem);

  console.log('add merge', items);
  console.log('new item', newItem);
  // Sort it
  items.sort((a, b) => {
    if (a.timestamp > b.timestamp) {
      return 1;
    }

    if (a.offset < b.timestamp) {
      return -1;
    }

    return 0;
  });

  // Make sure its uniq
  if (_.isEmpty(uniqByTerm)) {
    return _.uniqBy(items);
  } else {
    // Uniq by specific term
    // Reverse the item first,  so the highest timestamp get return, all others get override
    return _.uniqBy(_.reverse(items), (item) => {
      return item['data'][uniqByTerm]
    });
  }
}

export function useLatest(arr) {
  if (arr && arr.length > 0) {
    if (arr.length === 1) {
      return arr[0];
    } else {
      let last = arr[0];
      for (let i = 1; i < arr.length; i++) {
        let current = arr[i];

        if (current.timestamp > last.timestamp) {
          last = current;
        }
      }

      return last;
    }
  } else {
    return false;
  }
}

// Normalise the data to new standard
// so all the old data still works
export function normaliseData(data, ts) {
  if (_.isEmpty(data)) {
    return false;
  }

  // Check for timestamp
  if (data.timestamp) {
    return data;
  } else {
    let timestamp = + new Date();

    // Use the pass in timestamp if it have one
    if (ts) {
      timestamp = ts;
    }
    return {
      timestamp,
      data,
    }
  }
}

// Handle the common add merge
export function handleAddMerge(type, state, stageSlug, newItem, uniqByTerm) {
  let oldStageData = [];
  if (state[type][stageSlug]) {
    oldStageData = state[type][stageSlug];
  }

  let allData = state[type];

  // Make sure the new item is not empty
  if (!_.isEmpty(newItem)) {
    allData[stageSlug] = addMerge(oldStageData, newItem, uniqByTerm);
  }

  console.log('new ', type, allData);
  return allData;
}

// get topic name from stage
export function topicNameFromStage(stage) {
  return `${stage._id}_${stage.slug}`;
}

/**
 * Mapping for the message key to states
 * these states all have following structure
 * posts: {
 *  stage-1: [
 *    d1, d2, d3
 *  ],
 *  stage-2: [
 *    d1, d2, d3
 *  ]
 */
const messageKeyToStateMapping = {
  map: 'maps',
  post: 'posts',
  situation: 'situations',
  result: 'results',
  location: 'locations',
}
// Handle chunk message
export function handleMessageChunk(state, data) {
  if (data.length > 0) {

    // The result in here is have same struct as the state object
    let result = data.reduce((acc, item) => {
      // Figure out the topic and key/type
      // Before the old structure doesn't have topic and key/type in the value itself
      let topicName = null;
      let keyName = null;
      let value = null;
      if (_.isEmpty(item['_topic'])) {
        topicName = item.topic;
        keyName = item.key;
        value = item.value;
      } else {
        topicName = item['_topic'];
        keyName = item['_type'];
        value = item;
      }

      console.log('each item', topicName, keyName, value);

      // Check if we can map the item's key or not
      let stateKey = messageKeyToStateMapping[keyName];
      if (!_.isEmpty(stateKey)) {
        if (_.isEmpty(acc[stateKey])) {
          acc[stateKey] = {};
        }

        // Check location for integrity
        if (keyName === 'location') {
          console.log('location data');
          // Check for the data
          if (value.data) {
            if (value.data[0] > 90 || value.data[0] < -90) {
              console.log('its greater than 90 bound');
              return acc;
            }

            if (value.data[1] > 180 || value.data[1] < -180) {
              console.log('its greater than 180 bound');
              return acc;
            }
          }
        }

        // Check if the topic key exist or not
        if (_.isEmpty(acc[stateKey][topicName])) {
          acc[stateKey][topicName] = []
        }

        // Assign to the belonging topic
        acc[stateKey][topicName].push(normaliseData(value, item.offset));
      }

      // Return the accumulation
      return acc;
    }, {});

    // Only pick the state we want to update
    let stateToUpdate = _.pick(state, _.keys(result));

    // Make a copy of the state to update
    // So the merge doesn't mutate original state
    let toUpdate = _.cloneDeep(stateToUpdate);
    return _.merge(toUpdate, result);
  } else {
    return {};
  }
}

// Handle chunk message
export function handleMessages(existingInput, data) {
	let existingData = _.cloneDeep(existingInput);
	debugOutput('begining data and existing data******************', data, existingData);

	if (existingData['posts'] && existingData['posts']) {
		debugOutput('begining data and existing data******************', existingData['posts']);
	}

	if (data.length > 0) {

		debugOutput('this is before calculating the results');
		let result = incomingDataToExistingStructure(data);

		debugOutput('sorted results and existing data******************', result, existingData);

		// Sort the result
		result = sortByTimestamp(result);

		// Also sort the existing data as well
		existingData = sortByTimestamp(existingData);

		debugOutput('sorted results and existing data******************', result, existingData);

		// Go through each datatype to see if there is new on results
		// That not in the existing
		for (let dType of Object.values(messageKeyToStateMapping)) {
				debugOutput('dType', dType);
        if (result[dType] && !existingData[dType]) {
          // the existing data doesn't have it, then just add the whole thing
          existingData[dType] = result[dType];
        } else if (result[dType]) {
						debugOutput('keys in this dType', result[dType], typeof result[dType], Object.keys(result[dType]));
						let newKeys = _.difference(Object.keys(result[dType]), Object.keys(existingData[dType]));

						// Add this new key to the existing data
						if (newKeys.length > 0) {
								for (let newKey of newKeys) {
										// going to add the new stage name
										debugOutput('going to add new stage name', newKey);
										existingData[dType][newKey] = result[dType][newKey];
								}
						}
				}
		}

		debugOutput('sorted results and existing data******************', result, existingData);

		// Construct a new existing data that's merge and sort two array into a third sorted array
		for (let dType of Object.keys(existingData)) {
				for (let topic of Object.keys(existingData[dType])) {
						if (result[dType] && result[dType][topic]) {
								// merge the two sorted array
								debugOutput('going to merge', dType, topic);
								existingData[dType][topic] = mergeByTimestamp(existingData[dType][topic], result[dType][topic]);
						} else {// do nothing
						}
				}
		}

		debugOutput('sorted results and existing data*******************', result, existingData);
		return existingData;
	} else {
			return {};
	}
}

export function incomingDataToExistingStructure(data) {
    debugOutput('this is inside the gettng data into the same format', data);
    // The result in here is have same struct as the existing object
    return data.reduce((acc,item)=>{
        // Figure out the topic and key/type
        // Before the old structure doesn't have topic and key/type in the value itself
        let topicName = null;
        let keyName = null;
        let value = null;
        if (_.isEmpty(item['_topic'])) {
            topicName = item.topic;
            keyName = item.key;
            value = item.value;
        } else {
            topicName = item['_topic'];
            keyName = item['_type'];
            value = item;
        }

        debugOutput('each item', topicName, keyName, value);

        // Check if we can map the item's key or not
        let stateKey = messageKeyToStateMapping[keyName];
        if (!_.isEmpty(stateKey)) {
            if (_.isEmpty(acc[stateKey])) {
                acc[stateKey] = {};
            }

            // Check location for integrity
            if (keyName === 'location') {
                debugOutput('location data');
                // Check for the data
                if (value.data) {
                    if (value.data[0] > 90 || value.data[0] < -90) {
                        debugOutput('its greater than 90 bound');
                        return acc;
                    }

                    if (value.data[1] > 180 || value.data[1] < -180) {
                        debugOutput('its greater than 180 bound');
                        return acc;
                    }
                }
            }

            // Check if the topic key exist or not
            if (_.isEmpty(acc[stateKey][topicName])) {
                acc[stateKey][topicName] = []
            }

            // Assign to the belonging topic
            acc[stateKey][topicName].push(value);
        }

        // Return the accumulation
        return acc;
    }
    , {});
}

export function sortByTimestamp(data) {
    for (let dataType in data) {
        for (let topic in data[dataType]) {
            data[dataType][topic] = _.sortBy(data[dataType][topic], ['timestamp']);
        }
    }

    return data;
}

//sortByTimestamp(existingData);


// Merge two array by and order by timestamp low to high
export function mergeByTimestamp(input1, input2) {
    const result = [];
    let running = true;

    // Sort both array first
    const arr1 = _.sortBy(input1, 'timestamp');
    const arr2 = _.sortBy(input2, 'timestamp');

    const addedTimestamps = {};
    const addToResult = (input)=>{
        if (!addedTimestamps[input.timestamp]) {
            result.push(input);
            addedTimestamps[input.timestamp] = true;
        }
    }

    do {
        // Nothing to do if there is nothing in the array
        if (arr1.length === 0 && arr2.length === 0) {
            running = false;
        } else {
            // Check if they equal or not
            if (arr1.length > 0 && arr2.length === 0) {
                addToResult(arr1.shift());
            } else if (arr1.length === 0 && arr2.length > 0) {
                addToResult(arr2.shift());
            } else {
                // Compare the two value
                const a = new Date(arr1[0].timestamp).getTime();
                const b = new Date(arr2[0].timestamp).getTime();
                if (a > b) {
                    addToResult(arr2.shift());
                } else if (b > a) {
                    addToResult(arr1.shift());
                } else {
                    // equal just pick one and take it off both
                    addToResult(arr1.shift());
                    arr2.shift();
                }
            }
        }
    } while (running);return result;
}
