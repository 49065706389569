import { connect } from 'react-redux';
import ResultsCom from '../components/Results.js';

import {
  topicNameFromStage,
} from '../share/utils.js';

const mapStateToProps = (state) => {
  // Figure out the results
  let results = [];
  const topicName = topicNameFromStage(state.stage.settings);
  if (state.stage.results[topicName]) {
		results = state.stage.results[topicName];
  }

	return {
    results,
	}
}

// Connect the props to components
const Results = connect(
	mapStateToProps
)(ResultsCom);

// Export the module
export default Results;
