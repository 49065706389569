import React, { Component } from 'react';
import _ from 'lodash';

import {
  Sparklines,
  SparklinesLine,
  SparklinesSpots,
  SparklinesReferenceLine,
} from 'react-sparklines';

function boxMullerRandom () {
    let phase = false,
        x1, x2, w;

    return (function() {

        if (phase = !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
}

class LiveFeeds extends Component {
  hrTimer = null;
  caTimer = null;

  constructor(props) {
    super(props);

    this.state = {
      heartRates: randomData(),
      cadences: randomData(),
    };

    this.hrTimer = setInterval(()=> {
      this.setState({
        heartRates: this.state.heartRates.concat([boxMullerRandom()]),
      });
    }, 300);

    this.caTimer = setInterval(()=> {
      this.setState({
        cadences: this.state.cadences.concat([boxMullerRandom()]),
      });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.hrTimer);
    clearInterval(this.caTimer);
  }

	// Render the component
  render() {
    return (
      <div className="live-feeds">
        <div className="stats-heart-rate left half-width">
          <h3>Heart Rate</h3>
          <Sparklines data={this.state.heartRates} limit={20}>
              <SparklinesLine color="#1c8cdc" />
              <SparklinesSpots />
              <SparklinesReferenceLine type="mean" />
          </Sparklines>
        </div>

        <div className="stats-cadence right half-width">
          <h3>Cadance</h3>
          <Sparklines data={this.state.cadences} limit={20}>
              <SparklinesLine color="#1c8cdc" />
              <SparklinesSpots />
              <SparklinesReferenceLine type="mean" />
          </Sparklines>
        </div>

        <div className="clear"></div>
      </div>
    );
  }
}

export default LiveFeeds;
