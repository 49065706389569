import React, { Component } from 'react';
import Result from './Result.js';

// Styling
class Results extends Component {
  render() {
    console.log('properties of results', this.props);
    if (!this.props.results || this.props.results.length === 0) {
      return <div/>
    }
    const items = this.props.results.map((result) => (
      <Result
        key={result.timestamp}
        data={result.data}
      />
    ));

    console.log('result items', items);
    return (
      <div className="result-container">
        { items }
      </div>
    )
  }
}

export default Results;
