import { connect } from 'react-redux';
import RaceWrapperCom from '../components/RaceWrapper.js';

// Actions
import {
  getSettings,
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
    race: state.race,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getSettings: (slug) => {
			dispatch(getSettings('race', slug));
		},
	}
}

// Connect the props to components
const RaceWrapper = connect(
	mapStateToProps,
	mapDispatchToProps,
)(RaceWrapperCom);

// Export the module
export default RaceWrapper;
