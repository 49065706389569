import { connect } from 'react-redux';
import HomeCom from '../components/Home.js';

// Actions
import {
  getRaces,
  changeBarTitle,
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
    races: state.overall.races,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getRaces: () => {
			dispatch(getRaces());
		},
    appBarTitle: (title) => {
      dispatch(changeBarTitle(title));
    }
	}
}

// Connect the props to components
const Home = connect(
	mapStateToProps,
	mapDispatchToProps,
)(HomeCom);

// Export the module
export default Home;
