import React from 'react';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';

function contentRawToObject(rawContent) {
  // Hashtag config
  const hashConfig = {
    trigger: '#',
    separator: ' ',
  };

  // Handle custom entities
  // e.g mention and images
  let mentions = [];
  let images = [];
  const customEntityFunc = function(entity) {
    switch (entity.type) {
      case 'MENTION':
        mentions.push(entity.data);
        // Do mention specific stuff
        return '<a href="#">' + entity.data.text + '</a>';
      case 'IMAGE':
        images.push(entity.data);
        return '<img src="' + entity.data.src + '" />';
      default:
        return '';
    }
  }

  // Actually convert it to html
  const html = draftToHtml(rawContent, hashConfig, true, customEntityFunc);

  return {
    html,
    mentions,
    images,
  }
}

function onClickMentioned (e, mention, onClickMention) {
  e.preventDefault();
  console.log('clicked mention', mention);
  onClickMention(mention.data.url);
}

const Post = ({ post, onClickMention }) => {
  console.log('going to display indivisual data', post);
  // This is the hacky solution for now
  let contentJson = {};
  let publishDate = '';
  if (post.data) {
    publishDate = post.data.publishDate;

    if (typeof post.data.content === 'string') {
      contentJson = JSON.parse(post.data.content);
    } else {
      contentJson = post.data.content;
    }
  } else {
    publishDate = post.publishDate;
    contentJson = JSON.parse(post.content);
  }

  let blocks = contentJson.blocks;
  let entityMap = contentJson.entityMap;

  return (
    <li>
      <p className="publish-date">{moment(publishDate).format('LLL')}:</p>
      {blocks.map((block) => {
        // Check if the entity range contain mention or not
        let mentioned = block.entityRanges.some((entity) => {
          return entityMap[entity.key].type === 'MENTION';
        })

        if (mentioned) {
          let resultBlocks = [];
					let inputText = block.text;
					let textPos = 0;

					// Go through entity ranges and output the blocks
					block.entityRanges.forEach((range, index) => {
							let entity = entityMap[range.key];
							if (entity.type === "MENTION") {
                //console.log(`text pos- ${textPos} range offset - ${range.offset} length - ${range.length}`);
                resultBlocks.push(inputText.substr(textPos, range.offset - textPos));

                // Add the mention block
                resultBlocks.push(
                  <a href="#" onClick={(e) => onClickMentioned(e, entity, onClickMention)} key={index}>{entity.data.text}</a>
                );

                // Increase the text position
                textPos = range.offset + range.length;
							}
					})

					// Catch the end of the string
					if (textPos < block.text.length) {
							resultBlocks.push(inputText.substr(textPos, block.text.length - textPos));
					}

          return <div className="mention" key={block.key}>{resultBlocks}</div>;
        } else {
          // Fake the content and rely on other to do the rendering
          let content = contentRawToObject({
            blocks:[block],
            entityMap,
          });
          return <div key={block.key} dangerouslySetInnerHTML={{__html: content.html}} />
        }
      })}
    </li>
  )
}

export default Post;
