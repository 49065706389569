import React from 'react';
import {
  Route,
} from 'react-router-dom';

import Home from './containers/Home.js';
import StageWrapper from './containers/StageWrapper.js';
import RaceWrapper from './containers/RaceWrapper.js';
import UserWrapper from './containers/UserWrapper.js';

const routes = [
	{
    name: 'home',
    path: '/',
    exact: true,
    component: Home,
	},
  {
    name: 'users',
    path: '/u/:userName/',
    component: UserWrapper,
    routes: [
      {
        name: 'races',
        path: '/u/:userName/races/:raceSlug/',
        component: RaceWrapper,
        routes: [
          {
            name: "stage",
            path: '/u/:userName/races/:raceSlug/stages/:stageSlug',
            component: StageWrapper,
            routes: [
              /*
              {
                name: 'Live Report',
                path: '/races/:raceSlug/stages/:stageSlug/live',
                exact: true,
                component: LiveReport,
              },
              */
            ]
          },
        ]
      },
    ]
  }
];

export function RouteWithSubRoutes(route) {
  return <Route path={route.path} exact={route.exact} render={props => (
    // pass the sub-routes down to keep nesting
    <route.component {...props} routes={route.routes}/>
  )}/>
}

export default routes;
