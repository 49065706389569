import { connect } from 'react-redux';
import SituationsCom from '../components/Situations.js';

// Actions
import {
  toggleSecondMenu,
  mentionParticipant,
  getParticipant,
} from '../store/actions.js';

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		clickMention: (handle) => {
			console.log('going to toggle secondary menu');
			dispatch(toggleSecondMenu());
			dispatch(mentionParticipant(handle));
      dispatch(getParticipant(handle));
		},
	}
}

// Connect the props to components
const Situations = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SituationsCom);

// Export the module
export default Situations;

