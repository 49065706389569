import React, { Component } from 'react';

// Styling
import { List, ListItem } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

class Situation extends Component {
  render() {
    console.log('this is inside single situation');
    const {
      title,
      subTitle,
      desc,
      items,
    } = this.props.data;

    return (
      <div>
        <h3>{ title }</h3>
        <p>{ subTitle }</p>

        <div>{ desc }</div>

        <List>
        {items.map((item) => (
          <ListItem
            onClick={() => this.props.clickMention(item.handle)}
            key={item.handle}
            leftAvatar={
              <Avatar
                src={item.avatar}
                size={30}
              />
            }
          >
            {item.name}
          </ListItem>
        ))}
        </List>
      </div>
    )
  }
}

export default Situation;
