import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
	Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

const AppBarCom = ({ title, stageStatus, toggleMenu, toggleSecondMenu, classes }) => {

	return (
      <AppBar position="static">
				<Toolbar>
					<IconButton onClick={(e) => toggleMenu()} className={classes.menuButton} color="inherit" aria-label="Menu">
						<MenuIcon />
					</IconButton>

					<Typography variant="h6" color="inherit" className={classes.grow}>
						{title}
					</Typography>
				</Toolbar>
			</AppBar>
	)
}

export default withStyles(styles)(AppBarCom);
