// @flow
import PropTypes from 'prop-types'
import {
  Path,
  withLeaflet,
} from 'react-leaflet'
import { circleMarker } from 'leaflet'

class LiveMarker extends Path {
  static propTypes = {
    //pos: PropTypes.isRequired,
    //children: PropTypes.children,
    //radius: PropTypes.number,
  }

  createLeafletElement(props: Object): Object {
    console.log('live marker create element', props);
    const { pos, ...options } = props
    const liveMarker = circleMarker(pos, this.getOptions(Object.assign({
      radius: 5,
      color: 'orange',
      fillColor: '#333',
      fillOpacity: 1,
      className: 'leaflet-marker-live',
    }, options)))

    // Add click event
    liveMarker.on('click', function(event) {
      console.log('live marker clicked', event);
    });

    // Return the live marker
    return liveMarker;
  }

  updateLeafletElement(fromProps: Object, toProps: Object) {
    console.log('live marker update element', fromProps, toProps);
    if (toProps.pos !== fromProps.pos) {
      this.leafletElement.setLatLng(toProps.pos)
    }
    if (toProps.radius !== fromProps.radius) {
      this.leafletElement.setRadius(toProps.radius)
    }
  }
}

export default withLeaflet(LiveMarker);
